define('ember-tumblr/serializers/tumblr-post', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  exports['default'] = _emberData['default'].JSONSerializer.extend({
    /**
     * Post Type
     *
     * @property type
     * @type {String}
     * @default ''
     */
    type: '',

    /**
     * Use Post Type to determine model name for serializing
     *
     * @method modelNameFromPayloadKey
     * @param {String} payloadKey The incoming payload key
     * @return {String} The modified key
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var type = this.get('type');
      if (_ember['default'].isPresent(type)) {
        return this._super(payloadKey.substring(0, payloadKey.length - 1) + '-' + type);
      }
      return this._super(payloadKey);
    },

    /**
     * Normalize the payload to match our models
     *
     * @method normalizePayload
     * @param {Object} payload The incoming payload
     * @return {Object} The modified payload
     * @public
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.response && payload.response.posts) {
        var _ret = (function () {
          var type = _this.get('type') ? '-' + _this.get('type') : '';
          var data = payload.response.posts.map(function (post) {
            post.date = _this.normalizeDate(post.date);
            delete post.tags;
            return {
              id: post.id,
              type: 'tumblr-post' + type,
              attributes: post
            };
          });
          return {
            v: { data: data }
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
      return payload;
    },

    /**
     * Convert date from Tumblr API format to ISO string
     *
     * @method normalizeDate
     * @param {String} date The date string
     * @return {Date} normalized date object
     * @private
     */
    normalizeDate: function normalizeDate(date) {
      date = date.split(' ');
      date.pop(); // remove GMT from date string
      return date.join('T') + '.000Z';
    }
  });
});