define('ember-tumblr/adapters/tumblr-post', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  exports['default'] = _emberData['default'].RESTAdapter.extend({
    /**
     * Tumblr Application API Key
     * @type {string}
     * @default
     */
    apiKey: '',

    /**
     * Tumblr Blog URL
     * @type {string}
     * @default
     */
    blogUrl: '',

    /**
     * Tumblr API Hostname
     * @type {string}
     * @default
     */
    host: 'https://api.tumblr.com',

    /**
     * Post Type
     * @type {string}
     * @default
     */
    type: '',

    /**
     * Build path from type
     * @returns {string} API path with type string appended
     */
    pathForType: function pathForType() {
      var type = _ember['default'].isPresent(this.get('type')) ? '/' + this.get('type') : '';
      return 'posts' + type;
    },

    /**
     * Build namespace from blogURL
     * @returns {string} Tumblr API namespace with blogURL appended
     */
    namespace: _ember['default'].computed('blogURL', function () {
      return 'v2/blog/' + this.get('blogUrl');
    }),

    /**
     * Build hash for AJAX call
     * Appends API key and sets data type
     * @returns {object} hash of AJAX options
     */
    ajaxOptions: function ajaxOptions() {
      var hash = this._super.apply(this, arguments);
      hash.data = hash.data || {};
      hash.data.api_key = this.get('apiKey');
      hash.dataType = 'jsonp';
      return hash;
    }
  });
});