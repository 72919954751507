define('lodash/math/max', ['exports', 'lodash/internal/createExtremum', 'lodash/lang/gt'], function (exports, _lodashInternalCreateExtremum, _lodashLangGt) {
  'use strict';

  /** Used as references for `-Infinity` and `Infinity`. */
  var NEGATIVE_INFINITY = Number.NEGATIVE_INFINITY;

  /**
   * Gets the maximum value of `collection`. If `collection` is empty or falsey
   * `-Infinity` is returned. If an iteratee function is provided it is invoked
   * for each value in `collection` to generate the criterion by which the value
   * is ranked. The `iteratee` is bound to `thisArg` and invoked with three
   * arguments: (value, index, collection).
   *
   * If a property name is provided for `iteratee` the created `_.property`
   * style callback returns the property value of the given element.
   *
   * If a value is also provided for `thisArg` the created `_.matchesProperty`
   * style callback returns `true` for elements that have a matching property
   * value, else `false`.
   *
   * If an object is provided for `iteratee` the created `_.matches` style
   * callback returns `true` for elements that have the properties of the given
   * object, else `false`.
   *
   * @static
   * @memberOf _
   * @category Math
   * @param {Array|Object|string} collection The collection to iterate over.
   * @param {Function|Object|string} [iteratee] The function invoked per iteration.
   * @param {*} [thisArg] The `this` binding of `iteratee`.
   * @returns {*} Returns the maximum value.
   * @example
   *
   * _.max([4, 2, 8, 6]);
   * // => 8
   *
   * _.max([]);
   * // => -Infinity
   *
   * var users = [
   *   { 'user': 'barney', 'age': 36 },
   *   { 'user': 'fred',   'age': 40 }
   * ];
   *
   * _.max(users, function(chr) {
   *   return chr.age;
   * });
   * // => { 'user': 'fred', 'age': 40 }
   *
   * // using the `_.property` callback shorthand
   * _.max(users, 'age');
   * // => { 'user': 'fred', 'age': 40 }
   */
  var max = (0, _lodashInternalCreateExtremum['default'])(_lodashLangGt['default'], NEGATIVE_INFINITY);

  exports['default'] = max;
});